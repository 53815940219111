$primary-color: #2d59f2;
$secondary-color: #1e3ca8;
$bg-color: #f4f4f4;
$text-color: #333;

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: $bg-color;
}

.header {
  background: $primary-color;
  color: white;
  text-align: center;
  padding: 20px;
  font-size: 24px;
  font-weight: bold;
}

.container {
  max-width: 900px;
  margin: 30px auto;
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

h1 {
  color: $primary-color;
}

p {
  color: $text-color;
  line-height: 1.6;
}

.btn {
  display: inline-block;
  margin-top: 20px;
  padding: 12px 25px;
  background: $primary-color;
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 5px;
  transition: background 0.3s;
  &:hover {
    background: $secondary-color;
  }
}

.features,
.how-it-works {
  text-align: left;
  margin-top: 20px;
  padding: 20px;
}

.features ul,
.how-it-works ol {
  list-style: none;
  padding: 0;
}

.features li,
.how-it-works li {
  padding: 10px 0;
  color: #555;
  display: flex;
  align-items: center;
  &::before {
    content: "✔";
    color: $primary-color;
    font-weight: bold;
    margin-right: 10px;
  }
}

.footer {
  text-align: center;
  padding: 20px;
  background: $primary-color;
  color: white;
  margin-top: 30px;
  footer {
    margin-bottom: 12px;
    a {
      color: white;
    }
  }
}
