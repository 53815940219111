.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .titleBody {
    text-align: center;
    max-width: 600px;
  }
  .removeSpace {
    margin: 0;
    padding: 0;
  }

  .gridContainer {
    display: grid;
    gap: 20px;
    width: 100%;
    max-width: 1200px;
    margin: 48px auto;

    // Default for larger screens (2 rows with 3 items)
    grid-template-columns: repeat(3, 1fr);

    .gridItem {
      padding: 20px;
      background-color: var(--color-counter-cards);
      border: 2px solid var(--color-border-counter-cards);
      text-align: center;
      min-height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 24px;
    }

    // Medium screens (1024px or smaller): 3 rows with 2 items
    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
    }

    // Small screens (768px or smaller): 6 rows with 1 item each
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
}
