.container {
  background-color: var(--color-gray);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  bottom: 0;
  padding: 48px 5%;
  h3 {
    align-self: center;
  }
  .wrapper {
    display: flex;
    justify-content: space-between;
    gap: 48px;
    .linkContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .link {
        text-align: center;
        padding: 6px;
        color: black;
        text-decoration: none;
        &:hover {
          cursor: pointer;
          color: black;
        }
        &:active {
          cursor: pointer;
          color: black;
        }
      }
    }
  }
}
