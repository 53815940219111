.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .titleBody {
    text-align: center;
    max-width: 600px;
  }
  .mainContainerText {
    max-width: 600px;
    font-size: 16px;
    font-weight: bold;
  }
  .maxWidth {
    max-width: 600px;
  }
}
