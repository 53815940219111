.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .titleBody {
    text-align: center;
    max-width: 600px;
  }
  .removeSpace {
    margin: 0;
    padding: 0;
  }
}
