.container {
  height: 84px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .header {
    font-weight: 600;
    &:hover {
      cursor: pointer;
    }
  }
  .navigationContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    // Dropdown styling
    .dropdown {
      position: relative;
      display: inline-block;
    }

    // Link styling
    .link {
      cursor: pointer;
      text-decoration: none;
      padding: 10px;
      color: #000;

      &:hover {
        cursor: pointer;
        color: black;
      }

      &:active {
        cursor: pointer;
        color: black;
      }
    }

    // Dropdown content styling
    .dropdownContent {
      display: block;
      position: absolute;
      background-color: #f9f9f9;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
      transition:
        opacity 0.3s ease,
        visibility 0.3s ease;
    }

    // Dropdown button styling
    .dropdownButton {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;

      &:hover {
        background-color: #f1f1f1;
      }
    }

    // Mobile icon (hamburger menu)
    .mobileIcon {
      display: none; // Hide the icon on desktop
      font-size: 24px;
      cursor: pointer;
      padding: 10px;
      .mobileDropdownContent {
        display: block;
        position: absolute;
        right: 12px;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        transition:
          opacity 0.3s ease,
          visibility 0.3s ease;
      }

      // Dropdown button styling
      .dropdownButton {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        font-size: 16px;

        &:hover {
          background-color: #f1f1f1;
        }
      }
    }

    // Menu styling
    .menu {
      display: flex;
    }

    @media (max-width: 768px) {
      // Hide the menu on mobile
      .menu {
        display: none;
      }

      // Show the mobile icon on mobile
      .mobileIcon {
        display: block;
      }

      // When the icon is clicked, show the menu
      .menuOpen {
        display: flex;
        align-items: center;
      }
    }
  }
}
