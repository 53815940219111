/* Import Montserrat font */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

:root {
  --color-gray: #f9f9f9;
  --color-black: #000000;
  --color-primary-button: #2d59f2;
  --button-color: #ffffff;
  --page-padding: 0 5%;
  --color-counter-cards: linear-gradient(180deg, #cfdbee, #ecf3fd);

  --color-border-counter-cards: #2d59f2;
}

body,
html {
  height: 100%;
  margin: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  padding: var(--page-padding);
}
.content {
  flex: 1;
  padding: var(--page-padding);
}

/* Apply the font to body, headings, and paragraphs */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Montserrat", sans-serif;
}

/* Customize the weight for headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700; /* Bold for headings */
}

/* Normal weight for body text and paragraphs */
p,
body {
  font-weight: 400;
}

.spacer {
  padding-top: 24px;
  padding-bottom: 24px;
}

.spacer-xs {
  padding-top: 12px;
  padding-bottom: 12px;
}

.contained-button {
  background-color: var(--color-primary-button);
  padding: 10px 12px;
  font-size: 16px;
  text-decoration: none;
  color: var(--button-color);
  border-radius: 24px;
}

.swiper {
  max-width: 1200px !important;
  height: 100%;
  overflow: hidden;

  @media (max-width: 1200px) {
    max-width: 800px !important;
  }

  @media (max-width: 768px) {
    max-width: 100% !important;
  }
}

.swiper-slide {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center;
  font-size: 18px;

  // Set fixed dimensions for slides
  width: 100%; // Set a fixed width for consistency
  height: 64px; // Set a fixed height to ensure consistent layout

  @media (max-width: 768px) {
    width: 100%; // Let slides be full-width on mobile
    height: auto; // Allow flexibility for height on smaller screens
  }
}

.swiper-slide img {
  display: block;
  width: 100%; // Set the width to be 100% of the slide's width
  height: 64px; // Fix the height to 64px
  object-fit: contain; // Ensure the image fits within its container without distortion
}
