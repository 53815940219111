.squareShow {
  border-radius: 50% !important;
}

.largeSize {
  display: block;
  width: 100%; // Set the width to be 100% of the slide's width
  height: 84px !important;
  object-fit: contain; // Ensure the image fits within its container without distortion
}

.images {
  display: block;
  width: 100%; // Set the width to be 100% of the slide's width
  height: 64px; // Fix the height to 64px
  object-fit: contain; // Ensure the image fits within its container without distortion
}
