.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .titleNoSpacer {
    padding: 0;
    margin: 0;
    text-align: center;
  }
  .titleBody {
    text-align: center;
    max-width: 600px;
  }
  .banner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: auto;
    max-width: 900px;
    border: 2px solid var(--color-gray);
    border-radius: 24px;
    @media (max-width: 600px) {
      width: 95%;
    }
  }
  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .counterContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 42px;
      .counter {
        min-width: 200px;
        background: var(--color-counter-cards);
        padding: 12px 24px;
        border-radius: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow:
          0 4px 8px rgba(45, 89, 242, 0.2),
          0 6px 20px rgba(45, 89, 242, 0.19);
        animation: scale-up 3s infinite;
        &:nth-child(1) {
          animation-delay: 0s;
        }

        &:nth-child(2) {
          animation-delay: 0.5s;
        }

        &:nth-child(3) {
          animation-delay: 1s;
        }

        &:nth-child(4) {
          animation-delay: 1.5s;
        }

        &:nth-child(5) {
          animation-delay: 2s;
        }
      }
    }
  }
}

@keyframes scale-up {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
