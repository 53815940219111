.windEffect {
  animation: wind-blow 3s forwards;
  position: relative;
}

@keyframes wind-blow {
  0% {
    transform: translateX(0) skewX(0deg);
    filter: blur(0);
  }
  20% {
    transform: translateX(-5px) skewX(-5deg);
    filter: blur(1px);
  }
  40% {
    transform: translateX(-10px) skewX(-10deg);
    filter: blur(2px);
  }
  60% {
    transform: translateX(-5px) skewX(-5deg);
    filter: blur(1px);
  }
  80% {
    transform: translateX(0) skewX(0deg);
    filter: blur(0);
  }
  100% {
    transform: translateX(0) skewX(0deg);
    filter: blur(0);
  }
}
