.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .titleBody {
    text-align: center;
    max-width: 600px;
  }
  .listSpace {
    padding-top: 6px;
  }
  .counterContainer {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 42px;
    .counter {
      min-width: 200px;
      background: var(--color-counter-cards);
      border: 1px solid var(--color-border-counter-cards);
      padding: 12px 24px;
      border-radius: 24px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: auto;
        height: 80px;
      }
    }
  }
}
